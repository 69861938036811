<template>
    <div class="toolkit-container">
        <div :class="classes" v-if="showToolkit">
            <!-- 帮助中心 -->
            <div v-if="isHelp" class="toolkit-item help" @click="clickHelp">
                <span class="iconfont icon-bangzhuzhongxin-01"></span>
                <div class="toolkit-title-wrap">
                    <span class="toolkit-title-text">帮助中心</span>
                </div>
            </div>
            <!-- 意见反馈 -->
            <div id="J_FEEDBACK" class="toolkit-item feedback" @click.stop="clickFeedback" ref="J_feedback">
                <span class="iconfont icon-yijianfankui-01"></span>
                <div class="toolkit-title-wrap">
                    <span class="toolkit-title-text">意见反馈</span>
                </div>
                <div v-if="feedbackNum > 0" class="send-back">
                    <span v-if="feedbackNum <= 9">
                        {{ feedbackNum }}
                    </span>
                    <span v-else>
                        9+
                    </span>
                </div>
            </div>
            <!-- 待办事项 -->
            <div class="toolkit-item pending" @click="clickPending">
                <span class="iconfont icon-daibanshixiang"></span>
                <div class="toolkit-title-wrap">
                    <span class="toolkit-title-text">待办事项</span>
                </div>
                <div v-if="dealtNum > 0" class="id-dot"></div>
            </div>
            <!-- AI机器人 -->
            <div class="toolkit-item chatBot" @click="clickChatBot">
                <span class="iconfont icon-AIgongzuozhuli-01"></span>
                <div class="toolkit-title-wrap">
                    <span class="toolkit-title-text">AI工作助理</span>
                </div>
            </div>
            <!-- 客服机器人 -->
            <div class="toolkit-item supportBot" @click="clickSupportBot">
                <span class="iconfont icon-zaixiankefu-01"></span>
                <div class="toolkit-title-wrap">
                    <span class="toolkit-title-text">在线客服</span>
                </div>
            </div>
            <div v-if="this.feedbackNum && showFeedback" class="send-back-tips" :style="sty()">
                <img class="tips" :src="tips" alt="tips">
                <p class="text">您反馈的信息有新的进展，前往查看</p>
            </div>
        </div>
        <div class="full-close" @mouseleave="handlerMouseleave" @mouseenter="handlerMouseenter"
            @click="handlerToolkitChange">
            <img :src="imgFullClose" class="full-img" alt="full">
            <span class="text">{{ showToolkit ? '收起' : '展开' }}</span>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { getToken } from "@/libs/auth";
import { debounce } from '@/components/scrollWrapper/Sub/libs/fnProcessing.js';
import Feedback from '@/xkUI/pages/Feedback/src/index.vue';
import createdFeedback from '@/xkUI/pages/Feedback/index.js';
import { EventTracking } from '@/eventTracking/index.js';
import browserInfo from '@/libs/browserInfo.js';
import browserTool from "browser-tool";

export default {
    name: "Toolkit",
    components: {
        Feedback
    },
    data() {
        return {
            agencyApi: process.env.VUE_APP_XKSERVER_FEED_AGENCY_API,
            feedback: null,
            animationToolkit: false,
            showToolkit: true,
            hoverFull: false,
            timer: null,
            showFeedback: false
        };
    },
    computed: {
        ...mapState({
            dealtNum: (state) => state.dealtNum,
            originRouters: (state) => state.originRouters,
            userRouters: (state) => state.userRouters,
            schoolName: (state) => state.schoolName,
            userName: (state) => state.userName,
            userId: (state) => state.userId,
            feedbackNum: (state) => state.feedbackNum
        }),
        chatBotUrl() {
            return process.env.VUE_APP_XK_SERVER_CHAT_BOT_URL;
        },
        isHelp(){
            let show  = false;
            if(this.originRouters && this.originRouters.length > 0){  
                show = this.originRouters.some(item => item.name === "systemHelp");
            }
            return show
        },
        tips() {
            return require(`@/assets/images/sendBack/tips.png`)
        },
        imgFullClose() {
            if (!this.animationToolkit && !this.hoverFull) {
                return require(`@/assets/images/toolkits/down.png`)
            }

            if (!this.animationToolkit && this.hoverFull) {
                return require(`@/assets/images/toolkits/down-h.png`)
            }

            if (this.animationToolkit && !this.hoverFull) {
                return require(`@/assets/images/toolkits/up.png`)
            }

            if (this.animationToolkit && this.hoverFull) {
                return require(`@/assets/images/toolkits/up-h.png`)
            }
        },
        classes() {
            const cl = 'toolkit-wrap';
            return [cl, { 'disappear': this.animationToolkit }]
        }
    },
    created() {
        let _this = this;
        this.$on('EVENT_OPEN_FEEDBACK', () => {
            if (_this.showToolkit) {
                _this.clickFeedback();
            }
        })
        this.init();
    },
    mounted() {
        this.$nextTick(() => {
            this.showFeedback = true;
        })
    },
    methods: {
        init() {
            this.bindEvent();
        },
        bindEvent() {
            window.addEventListener('resize', this.feedbackResizeEvent)
        },
        /**
         * @Description: 监听宽度
         * @Author: 武东超
         * @Date: 2024-09-06 14:48:31
         */        
        feedbackResizeEvent: debounce(function(){
            let width = document.documentElement.clientWidth;
            if (width <= 1600) {
                this.showToolkit = false;
            }
        }, 200),
        /**
         * @Description: 是否有反馈信息
         * @Author: 武东超
         * @Date: 2024-09-05 17:20:36
         */    
        getNewFeedback() {
            this.$store.dispatch('getHasNewFeedback')
        },    
        sty() {
            let ref = this.$refs.J_feedback;
            if (ref) {
                let refInfo = ref.getBoundingClientRect();
                console.log(refInfo, '位置')
                return {
                    'top': refInfo.top + 'px',
                    'right': '40px'
                }
            }
        },
        // 意见反馈
        async clickFeedback() {
            this.feedback && this.feedback.close();
            this.feedback = createdFeedback(Feedback, {
                projectId: '63803e5553e6416d79b106bf',
                schoolName: this.schoolName,
                userName: this.userName,
                userRouters: this.userRouters,
                originRouters: this.originRouters,
                userId: this.userId
            });

            try {
                const getBrowserInfo = browserInfo();
                const eventTracking = new EventTracking();
                const info = await browserTool.getInfo();
                const { browser, browserVersion, system, systemVersion } = info;
                let requestData = {
                    viewerIP: "",
                    platName: "校园端",
                    platType: "PC",
                    systemName: "云校园",
                    systemMark: "cloud_campus",
                    menuName: "意见反馈",
                    path: window.location.href,
                    browserName: (browser || "") + '_' + getBrowserInfo(), // 浏览器名称
                    browserVersion: browserVersion || "", // 浏览器版本
                    osName: system ? `${system} ${systemVersion}` : "", // 操作系统名称
                    currentToken: getToken(),
                    userId: this.userId,
                    userName: this.userName,
                };
                eventTracking.behavioralAnalysisServer(requestData)
            } catch (e) {
                console.log('[意见反馈信息]', e)
            }

        },
        // 待办事项
        clickPending() {
            this.$router.push({
                name: "DealtList",
            });
        },
        // AI机器人
        clickChatBot() {
            let res = `https://ask.xinkaoyun.com:8888/#/login?token=${getToken()}`;
            const _window = window.open();
            if (_window) {
                _window.location.href = res;
            } else {
                const a = document.createElement("a");
                a.href = res;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        },
        // 客服机器人
        clickSupportBot() {
            const host = this.chatBotUrl;
            const name = this.$store.state.loginName;
            const projectId = "63803e5553e6416d79b106bf";
            this._fet("/school/login/getLogLoginToken", {
                username: name,
                productId: "cloud_campus",
                projectId: "63803e5553e6416d79b106bf",
            }).then(e => {
                const r = e.data.data;
                window.open(`${host}/?systemMark=${r.systemMark}&projectId=${projectId}&token=${r.token}#/chat?pid=${r.projectId}`, "_blank");
            });
        },
        EVENT_OPEN_FEEDBACK() {
            console.log('123')
        },
        /**
         * @Description: 跳转帮助中心
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 周浩
         * @Date: 2024-07-05 17:22:32
         */
        clickHelp() {
            this.$router.push({ name: "systemHelp" });
        },
        /**
         * @Description: 鼠标滑出
         * @Author: 武东超
         * @Date: 2024-09-05 16:37:10
         */
        handlerMouseleave() {
            this.hoverFull = false;
        },
        /**
         * @Description: 鼠标滑入
         * @Author: 武东超
         * @Date: 2024-09-05 16:37:40
         */
        handlerMouseenter() {
            this.hoverFull = true;
        },
        /**
         * @Description: 点击切换
         * @Author: 武东超
         * @Date: 2024-09-05 16:46:03
         */
        handlerToolkitChange(type) {
            if (this.showFeedback) {
                this.showFeedback = false;
            }
            this.animationToolkit = this.showToolkit;
            if (!this.animationToolkit) {
                this.showToolkit = true;
                this.$nextTick(() => {
                    this.showFeedback = true;
                })
            } else {
                this.timer = setTimeout(() => {
                    this.showToolkit = false;
                }, 100);
            }
        }
    },
    beforeDestroy() {
        this.timer = null;
        window.removeEventListener('resize', this.feedbackResizeEvent)
    }
};
</script>
<style lang="scss" scoped>
.toolkit-container {
    position: fixed;
    right: 0;
    bottom: 30px;
    z-index: 2999;
    width: 39px;
    box-sizing: border-box;
    background: #f5f9ff;
    box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px 0 0 4px;

    .toolkit-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        .iconfont {
            font-size: 25px;
            color: var(--brand-theme);
        }

        .toolkit-item {
            cursor: pointer;
            position: relative;
            padding: 20px 0;

            +.toolkit-item:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 50%;
                transform: translate(-50%);
                width: 16px;
                height: 1px;
                background-color: #E2E2E2;
            }

            .toolkit-title-wrap {
                position: absolute;
                top: 50%;
                right: 39px;
                transform: translateY(-50%);
                background: var(--brand-theme);
                box-shadow: -3px 0px 8px 0px rgba(0, 0, 0, 0.1);
                border-radius: 4px 0 0 4px;
                height: 71.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 0;
                overflow: hidden;
                transition: width .15s ease-in;

                .toolkit-title-text {
                    white-space: nowrap;
                    font-size: 14px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }

            .id-dot {
                position: absolute;
                right: 5px;
                top: 7px;
                width: 7px;
                height: 7px;
                background: #F84141;
                border-radius: 3px;
            }
        }

        .toolkit-item:hover:has(:hover) {
            .toolkit-title-wrap {
                width: 104px !important;
            }
        }

        .feedback {
            padding-left: 8px;
            padding-right: 6px;
            position: relative;

            &:hover {
                .icon-yijianfankui-01 {
                    color: #FFFFFF;
                }
                background-color: var(--brand-theme);
            }

            .send-back {
                position: absolute;
                right: 2px;
                top: 13px;
                width: 18px;
                height: 18px;
                line-height: 18px;
                text-align: center;
                font-size: 12px;
                color: #FFFFFF;
                background: #F84141;
                border-radius: 50%;
                span {
                    transform: scale(0.8);
                }
            }
        }

        .pending {
            padding-left: 8px;
            padding-right: 6px;

            &:hover {
                .icon-daibanshixiang {
                    color: #FFFFFF;
                }
                background-color: var(--brand-theme);
            }
        }

        .help {
            padding-left: 8px;
            padding-right: 6px;

            &:hover {
                // img {
                //     content: url('~@/assets/images/toolkit/Help.png');
                // }
                .icon-bangzhuzhongxin-01 {
                    color: #FFFFFF;
                }
                background-color: var(--brand-theme);
            }
        }

        .chatBot {
            padding-left: 7px;
            padding-right: 7px;

            &:hover {
                .icon-AIgongzuozhuli-01 {
                    color: #FFFFFF;
                }
                background-color: var(--brand-theme);
            }
        }

        .supportBot {
            padding-left: 8px;
            padding-right: 7px;

            &:hover {
                .icon-zaixiankefu-01 {
                    color: #FFFFFF;
                }
                background-color: var(--brand-theme);
            }
        }
    }

    .disappear {
        animation: fadeOut 1s forwards;
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            // transform: translateY(100%);
        }
    }

    .full-close {
        position: relative;
        padding: 9px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .full-img {
            width: 15px;
        }
        .text {
            padding-top: 6px;
            text-align: center;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #747474;
        }
    }

    .full-close:after {
        content: '';
        position: absolute;
        top: 0px;
        left: 50%;
        -webkit-transform: translate(-50%);
        -moz-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
        width: 16px;
        height: 1px;
        background-color: #E2E2E2;
    }

    .full-close:hover {
        .text {
            color: #3C7FFF;
        }
    }

    .send-back-tips {
        width: 192px;
        height: 78px;
        position: fixed;
        background-image: url('@/assets/images/sendBack/send-back.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        .tips {
            width: 42px;
            height: 53px;
            margin-left: 8px;
        }

        .text {
            width: 126px;
            white-space: wrap;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
        }
    }
}
</style>
